import React, { FC } from "react";
import logo from "../../../../assets/logo.png";


export const MenuBar: FC = () => (
  <div className="menubar">
    <div style={{ padding: "10px" }}>
      <img src={logo} alt="SubReader" style={{ height: "40px" }} />
    </div>
    <div
      id="menubar-portal"
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        padding: "0px 20px",
        alignItems: "center",
      }}
    />
  </div>
);
