import React, { FC } from "react";
import { Link, withPrefix } from "gatsby";
import { globalHistory } from "@reach/router";

import homeIconActive from "../../../../assets/home-icon-active.png";
import metricsActive from "../../../../assets/metrics-icon-active.png";


const pages = [
  {
    name: "Dashboard",
    icon: homeIconActive,
    path: "/",
  },
  {
    name: "Metrics",
    icon: metricsActive,
    path: "/metrics/",
  },
];

export const SideBar: FC = () => (
  <div className="sidebar">
    {pages.map(page => (
      <Link key={page.name} to={page.path} className="sidebar-item">
        <img
          src={page.icon}
          alt={page.name}
          style={{
            width: "22px",
            height: "22px",
            opacity: globalHistory.location.pathname === withPrefix(page.path) ? 1 : 0.6,
          }}
        />
      </Link>
    ))}
  </div>
);
