import React, { FC } from "react";
import { Helmet } from "react-helmet";

import "./index.css";
import { MenuBar } from "./MenuBar";
import { SideBar } from "./SideBar";


export const Layout: FC = ({ children }) => {
  return (
    <div className="page-container">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="SubReader Dashboard" />
        <title>SubReader Dashboard</title>
      </Helmet>
      <MenuBar />
      <div style={{ display: "flex", flex: 1 }}>
        <SideBar />
        <div
          style={{
            margin: "0 auto",
            padding: "2em",
            flex: 1,
            overflowY: "scroll",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
